import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import store from './redux/reduxQueryStore';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

import { qie } from 'utils/BlockChain/QIE';

const chains = [qie]
export const projectId = '2c504daea9f8cd270f5a3e92fb8bcf41'

const { publicClient } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => ({
      http: `https://mainnet.qiblockchain.online/api`,
    }),
  }),
]); //[w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

export const ethereumClient = new EthereumClient(wagmiConfig, chains)

export const getQueries = state => state.queries;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <App />
        </ReduxQueryProvider>
      </WagmiConfig>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
reportWebVitals();
