import React from 'react';
import { useSelector } from 'react-redux';
import Nav from './components/nav';
import Footer from './components/footer';
import Banner from './components/Banner';
import VerifyEmailBanner from './components/VerifyEmailBanner';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
const noLayoutPaths = ['transactions/stripe'];

export default function Layout({ children }) {
  const location = useLocation();

  const isNoLayoutPath = noLayoutPaths.some(path =>
    location?.pathname?.includes(path),
  );

  if (isNoLayoutPath) return children;

  return (
    <div>
      <Box overflow="hidden">
        {/* {
        user.email && !user.verified && isLoggedIn() && showEmailVerifyBanner ? <VerifyEmailBanner closeBanner={()=>{setShowEmailVerifyBanner(false)}}/> : <Banner/>
      } */}
        <Nav />
        {children}
        <Footer />
      </Box>
    </div>
  );
}
