import { customToast } from 'components/common/Toast';

export const InitiateTransaction = data => {
  return {
    url: `/api/transactions`,
    body: data,
    transform: body => ({
      transactionInfo: body.data,
      transactionMode: body.data.paymentMode,
    }),
    update: {
      transactionInfo: (prev, next) => next,
      transactionMode: (prev, next) => next,
    },
  };
};

export const cancelOrderTransaction = ({ id }) => {
  return {
    url: `/api/orders/cancel-order-transaction/${id}`,
    // options:{
    //   method:"PUT"
    // },
    transform: body => ({
      myOrders: body.data,
    }),
    update: {
      myOrders: (prevValue, newValue) => newValue,
    },
  };
};

// export const updatePaypal = (transactionId, data) => {
//   return {
//     url: `/api/transactions/updatePaypal/${transactionId}`,
//     body: data,
//     update: {
//       transactionInfo: (prev, next) => null,
//       transactionMode: (prev, next) => null,
//     },
//   };
// };

export const CreateOrder = (data, history) => {
  return {
    url: `/api/orders`,
    body: data,
    transform: body => {
      history.push(`/order/${body.data}`);
      return {
        orderId: body.data,
      };
    },
    update: {
      orderId: (prev, next) => next,
    },
  };
};

export const GetMyOrders = () => {
  return {
    url: `/api/orders/myorders`,
    transform: body => ({
      myOrders: body.data,
    }),
    update: {
      myOrders: (prev, next) => next,
    },
  };
};

export const getTransactionById = transactionId => {
  return {
    url: `/api/transactions/${transactionId}`,
    transform: body => ({
      getTransactionByIdInfo: body.data,
    }),
    update: {
      getTransactionByIdInfo: (prev, next) => next,
    },
  };
};

export const GetOrder = id => {
  return {
    url: `/api/orders/${id}`,
    transform: body => {
      return {
        order: body.data,
        discountCoupon: body.data.discount,
        couponError: null,
      };
    },
    update: {
      order: (prev, next) => {
        return next;
      },
      discountCoupon: (prev, next) => next,
      couponError: (prev, next) => next,
    },
  };
};

export const getOrderToComplete = id => {
  return {
    url: `/api/orders/getDomainToConfigure/${id}`,
    transform: body => ({
      domainsToConfigure: body.data,
    }),
    update: {
      domainsToConfigure: (prev, next) => next,
    },
  };
};

export const updateDomainResolver = (id, data) => {
  return {
    url: `/api/orders/complete-domain-registration/${id}`,
    body: data,
  };
};

export const getMyDomains = () => {
  return {
    url: `/api/domains`,
    transform: body => ({
      myDomains: body.data,
      user: { totalDomains: body.data.length },
    }),
    update: {
      myDomains: (prev, next) => next,
      user: (prev, next) => {
        if (prev) {
          return { ...prev, ...next };
        }
      },
    },
  };
};

export const getTransactionStatus = txHash => {
  return {
    url: `/api/transactions/paymentstatussubscribe/${txHash}`,
    transform: body => ({
      txStatus: body.data,
    }),
    update: {
      txStatus: (prev, next) => next,
    },
  };
};

export const getPremiumDomain = ({ limit, skip, search }) => {
  let apiUrl = `/api/premium-domain?`;

  if (skip) {
    apiUrl += `skip=${skip}`;
  } else {
    apiUrl += `skip=0`;
  }
  if (limit) {
    apiUrl += `&limit=${limit}`;
  }
  apiUrl += `&search=${search}`;

  return {
    url: apiUrl,
    transform: body => ({
      premiumDomains: body.data,
    }),
    update: {
      premiumDomains: (prev, next) => next,
    },
  };
};

export const getTLD = () => {
  return {
    url: `/api/tld`,
    transform: body => ({
      tlds: body.result,
    }),
    update: {
      tlds: (prev, next) => next,
    },
  };
};

// export const getAvailableCoupons = (applyDiscountCoupon) => {
//   return {
//     url: `/api/orders/coupons/getAvailableCoupons`,
//     transform: body => {
//       let availableCoupons = body.data;
//
//       return { availableCoupons }
//     },
//     update: {
//       availableCoupons: (prev, next) => next
//     },
//   };
// };

// export const applyCoupon = (orderId, discountCoupon) => {
//
//   return {
//     url: `/api/orders/applyCoupon`,
//     body: {
//       orderId,
//       discountCoupon,
//     },
//     transform: body => {
//       if (body.success === true) {
//         return { order: body.data, couponError: null, discountCoupon: body.data.discount };
//       }
//       else {
//         return { order: body.data, couponError: { message: `${discountCoupon} is not applicable` }, discountCoupon: null };
//       }
//     },
//     update: {
//       order: (prev, next) => {
//         return next;
//       },
//       couponError: (prev, next) => {
//         if (next && next.message) {
//           return next.message;
//         }
//         return null;
//       },
//       discountCoupon: (prev, next) => {
//         return next;
//       }
//     },
//   };
// };

// export const removeCoupon = (orderId, discountCoupon, istoast) => {
//   return {
//     url: `/api/orders/removeCoupon`,
//     body: {
//       orderId,

//       discountCoupon,
//     },
//     transform: body => {
//       if (istoast) {
//         customToast({ title: "Success", description: "Coupon removed successfully" })
//       }
//       if (body.data) {
//         return { order: body.data, couponError: null, discountCoupon: body.data.discount };
//       }
//       return { couponError: null };
//     },
//     update: {
//       order: (prev, next) => next,
//       couponError: (prev, next) => {
//         return null;
//       },
//       discountCoupon: (prev, next) => {
//         return next;
//
//       }
//     },
//
//   };
// };

// export const getCreditsEnum = () => {
//   return {
//     url: `/api/credits/amounts`,
//     transform: body => ({
//       creditsOptions: body.result,
//     }),
//     update: {
//       creditsOptions: (prev, next) => next,
//     },
//   };
// };

// export const getInfluencers = () => {
//   return {
//     url: `/api/credits/influencer`,
//     transform: body => ({
//       influencerOptions: body.result,
//     }),
//     update: {
//       influencerOptions: (prev, next) => next,
//     },
//   };
// };

// export const addCredits = body => {
//   return {
//     url: '/api/credits/',
//     options: {
//       method: 'POST',
//     },
//     body: {
//       ...body,
//     },
//     transform: body => ({
//       creditsTransactionInfo: body.result,
//       creditsPaymentMode: body.result.paymentMode,
//     }),
//     update: {
//       creditsTransactionInfo: (prev, next) => next,
//       creditsPaymentMode: (prev, next) => next,
//     },
//   };
// };

// export const getUserCredits = () => {
//   return {
//     url: `/api/credits`,
//     transform: body => ({
//       myCredits: body.result,
//     }),
//     update: {
//       myCredits: (prev, next) => next,
//     },
//   };
// };
// export const getUserCreditHistory = () => {
//   return {
//     url: `/api/credits/history`,
//     transform: body => ({
//       myCredits: body.result,
//     }),
//     update: {
//       myCredits: (prev, next) => next,
//     },
//   };
// };

// export const getCorrespondingTokenForAmount = ({ amount }) => {
//   return {
//     url: `/api/credits/tokens`,
//     options: {
//       method: 'POST',
//     },
//     body: {
//       amount,
//     },
//     transform: body => ({
//       creditsNexbTokens: body.result,
//     }),
//     update: {
//       creditsNexbTokens: (prev, next) => next,
//     },
//   };
// };
