import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Flex,
  Icon,
  Wrap,
  WrapItem,
  Button,
  Divider,
} from '@chakra-ui/react';
import useViewport from 'utils/Hooks/useViewPort';
import {
  FiUser,
  FiInfo,
  FiList,
  FiCreditCard,
  FiEye,
  FiDollarSign,
  FiPhone,
  FiCornerRightUp,
} from 'react-icons/fi';
import { AiFillHeart } from 'react-icons/ai';
import { FaQuestionCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ConnectWallet from 'components/Wallet/ConnectWallet';

const LinkItems = [
  { name: 'Account', icon: FiUser, path: '/profile/view' },
  { name: 'My Domains', icon: FiList, path: '/my-domains' },
  // { name: 'Token', icon: FiCreditCard, path: '/token' },
  // { name: 'Premium domains', path: '/premium-domains', icon: AiFillHeart },
  { name: 'Domain Transactions', icon: FiDollarSign, path: '/transactions' },
  { name: 'Wishlist', icon: FiEye, path: '/wishlist' },
  { name: 'Support', icon: FiPhone, path: '/support' },
  { name: 'Crypto Transfer', icon: FiCornerRightUp, path: '/transfer' },
];

const MainNavItems = [
  {
    name: 'FAQ',
    icon: FaQuestionCircle,
    path: 'https://hovr.site/faqs',
  },
];

export const SidebarContent = ({ onClose, ...rest }) => {
  const history = useHistory();
  const { width } = useViewport();
  const breakpoint = 720;
  const isMobile = width < breakpoint;

  return (
    <Box {...rest}>
      <hr />
      {isMobile && (
        <Flex justifyContent="start" p="2" mx="6">
          <ConnectWallet />
        </Flex>
      )}

      {LinkItems.map(link => {
        return (
          <NavItem
            key={link.name}
            onClose={onClose}
            icon={link.icon}
            path={link?.path || '#'}
          >
            {link.name}

          </NavItem>
        );
      })}
      <Divider my="4" />
      <Wrap spacing="4">
        {isMobile
          ? MainNavItems.map(link => (
              <WrapItem>
                <MainNavItem
                  key={link.name}
                  icon={link.icon}
                  path={link?.path || '#'}
                >
                  {link.name}
                </MainNavItem>
              </WrapItem>
            ))
          : ''}
      </Wrap>
    </Box>
  );
};

const NavItem = ({ icon, onClose, children, path, ...rest }) => {
  return (
    <Flex
      align="center"
      p="3"
      mx="4"
      className="side-flex"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'brand.500',
        color: 'white',
        textDecoration: 'underline',
      }}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="22px"
          _groupHover={{
            color: 'white',
          }}
          color="brand.600"
          as={icon}
        />
      )}
      <Link
        onClick={() =>
          setTimeout(() => {
            onClose();
          }, 200)
        }
        key={icon}
        className="side-link"
        to={path}
      >
        {children}{' '}
      </Link>
    </Flex>
  );
};

const MainNavItem = ({ icon, children, path, ...rest }) => {
  return (
    <Button colorScheme="gray" as="a" target="_blank" href={path}>
      <Flex alignItems="center">
        {icon && (
          <Icon
            mr="4"
            _groupHover={{
              color: 'white',
            }}
            color="brand.600"
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Button>
  );
};
