let initialState = {
  selectedWallet: null,
  isWalletConnected: false,
  account: null,
};

export function walletReducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_WALLET':
      return {
        ...state,
        selectedWallet: action.data.selectedWallet
      }
    case 'CONNECT_WALLET':
      return {
        ...state,
        isWalletConnected: true,
        account: action.data.account,
      };

    case 'DISCONNECT_WALLET':
      return {
        ...state,
        isWalletConnected: false,
        account: null,
      };

    default:
      return state;
  }
}

export default walletReducer;
