import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { addNetworkToWallet } from 'utils/BlockChain/web3';
import { getQiNetwork, getQiNetworkDetails } from 'utils/BlockChain/qi';

function WalletNetworkButtons({ buttonSize = 'md', justifyContent = 'left' }) {
  const addNetworkToWalletHandler = async network => {
    let networkDetails;

    if (network === 'qi') {
      networkDetails = getQiNetworkDetails();
    }

    await addNetworkToWallet(networkDetails);
  };

  const qiNetwork = getQiNetwork();

  return (
    <Flex wrap="wrap" justifyContent={justifyContent}>
      <a href='https://docs.qiblockchain.online/fundamental-topics/how-can-i-add-qi-to-metamask' target='blank'>
      <Button
        // onClick={() => addNetworkToWalletHandler('qi')}
        color="pink.500"
        fontWeight="normal"
        borderRadius="full"
        zIndex="1"
        padding="1.5rem"
        bg="none"
        _hover={{ bg: 'none' }}
        _after={{
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          zIndex: '-1',
          borderRadius: 'full',
          padding: '1px', // Adjust border thickness
          background:
            'linear-gradient(90deg, #453FD6 0%, #FF136D 100%)',
          WebkitMask:
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'destination-out',
          maskComposite: 'subtract',
        }}

        size={buttonSize}
        mt={2}
      >
        {`Add QIE ${qiNetwork === 'mainnet' ? 'Mainnet' : 'Testnet'}`}
      </Button>
      </a>
    </Flex>
  );
}

export default WalletNetworkButtons;
