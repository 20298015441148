import {
  Button,
  chakra,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import * as React from 'react';
import Logo from 'components/common/Logo';
import WalletNetworkButtons from 'pages/Cart/components/WalletNetworkButtons';

class SubscribeForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const script = document.createElement('script');
    // script.src = 'https://js.hsforms.net/forms/v2.js';
    // document.body.appendChild(script);
    //
    // script.addEventListener('load', () => {
    //     if(window.hbspt) {
    //         window.hbspt.forms.create({
    //             portalId: '6271347',
    //             formId: '0e745f78-ea3b-44fa-86ed-2f0b1cd34e3f',
    //             target: '#hubspot-subscribe'
    //         })
    //     }
    // });
  }

  render() {
    const props = this.props;
    return (
        <Stack spacing="4">
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Logo />
            <WalletNetworkButtons justifyContent="center" />
          </Flex>
        </Stack>
    );
  }
}

export default SubscribeForm;
