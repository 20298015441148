import { Box, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import FooterHeading from './FooterHeading';
import { GoLinkExternal } from 'react-icons/go';
const LinkGrid = props => (
  <SimpleGrid columns={{ base: 1, md: 4 }} {...props}>
    <Box textAlign="left" minW="130px">
      <Text mb="4" color="#ff136d">
        Developers
      </Text>
      <Stack>
        <Link href="https://github.com/Qi-Blockchain" target="_blank">
          Github
        </Link>
        <Link href="https://docs.qiblockchain.online/" target="_blank">
          Developer Doc
        </Link>
        <Link href="https://validator.qiblockchain.online/" target="_blank">
          Validator
        </Link>
        <Link href="/QIE-Blockchain-Whitepaper.pdf" target="_blank">
          White Paper
        </Link>
        <Link href="https://mainnet.qiblockchain.online/" target="_blank">
          Explorer
        </Link>
        {/* <Link
          target="_blank"
          href="https://hovr.site/collections"
          color="#ffff"
        >
          Collections
        </Link>
        <Link
          target="_blank"
          href="https://hovr.site/support-tickets"
          color="#ffff"
        >
          Support Ticket
        </Link>
        <Link target="_blank" href="https://referral.hovr.site/" color="#ffff">
          Referral Program
        </Link> */}
      </Stack>
    </Box>

    <Box textAlign="left" minW="180px">
      <Text mb="4" color="#ff136d">
        Products
      </Text>

      <Stack textAlign="left">
        <Link
          color="#ffff"
          href="https://hovr.site/"
          target="_blank"
          display="inline-flex"
          alignItems="center"
        >
          hovr.site
          <GoLinkExternal style={{ marginLeft: '8px' }} />
        </Link>

        <Link
          display="inline-flex"
          alignItems="center"
          color="#ffff"
          href="https://www.qie.digital/"
          target="_blank"
        >
          qie.digital
          <GoLinkExternal style={{ marginLeft: '8px' }} boxSize={6} />
        </Link>

        <Link
          display="inline-flex"
          alignItems="center"
          color="#ffff"
          href="https://qidex.site/"
          target="_blank"
        >
          qidex.site
          <GoLinkExternal style={{ marginLeft: '8px' }} />
        </Link>

        <Link
          display="inline-flex"
          alignItems="center"
          color="#ffff"
          href="https://qiewallet.me/"
          target="_blank"
        >
          qiewallet.me
          <GoLinkExternal style={{ marginLeft: '8px' }} />
        </Link>

        <Link
          display="inline-flex"
          alignItems="center"
          color="#ffff"
          href="https://www.pawsome.host/"
          target="_blank"
        >
          pawsome.host
          <GoLinkExternal style={{ marginLeft: '8px' }} />
        </Link>

        {/* <Link target="_blank" href="https://hovr.site/faqs" color="#ffff">
          FAQ
        </Link>
        <Link
          target="_blank"
          href="https://hovr.site/help-center"
          color="#ffff"
        >
          Help Center
        </Link>
        <Link target="_blank" href="https://hovr.site/feedback" color="#ffff">
          Feedback{' '}
        </Link> */}
      </Stack>
    </Box>

    <Box textAlign="left" minW="130px">
      <Text mb="4" color="#ff136d">
        Legal
      </Text>
      <Stack>
        {/* <Link target='_blank' href="https://hovr.site/about" color="#ffff">About Us</Link>
        <Link target='_blank' href="https://hovr.site/privacy-policy" color="#ffff">Privacy Policy</Link> */}
        <Link href="https://medium.com/@QIEecosystem/" target="_blank">
          Blogs
        </Link>
        <Link
          target="_blank"
          href="https://www.qie.digital/terms-of-services"
          color="#ffff"
        >
       Terms & Service
        </Link>
      </Stack>
    </Box>

    <Box textAlign="left" minW="180px">
      <Text mb="4" color="#ff136d">
        Contact Us
      </Text>
      <Stack>
        <Link href="mailTo:support@qie.digital" color="#ffff">
        support@qie.digital
        </Link>
      </Stack>
    </Box>
  </SimpleGrid>
);

export default LinkGrid;
