import { updateEntities } from 'redux-query';
import store from 'redux/reduxQueryStore';
import { addCartItemLocalStorage, setCartItems } from 'utils/Cart/cart';
import { customToast } from 'components/common/Toast'
import { nanoid } from 'nanoid'

export const updateCartEntity = (cartDetails) => {
  setCartItems(cartDetails);
  store.dispatch(updateEntities({
    cartDetails: (prevValue) => cartDetails,
  }))
};

export const addToCartEntity = (newDomain) => {

  addCartItemLocalStorage(newDomain);
  customToast({ title: "Success", description: " Domain successfully added to cart" })
  store.dispatch(updateEntities({
    cartDetails: (prevValue) => [...prevValue, { ...newDomain, _id: nanoid() }],
  }))
};


export const addToCartMutation = (data) => {
  return {
    url: `/api/cart`,
    body: data,
    transform: (responseBody) => {
      const { result } = responseBody || {};

      return { cartDetails: result?.items || [] };
    },
    update: {
      cartDetails: (oldValue, newValue) => {
        customToast({ title: "Success", description: "Domain successfully added to cart" })
        return newValue;
      }
    }
  }
}

export const deleteFromCartMutation = ({ id }) => {
  return {
    url: `/api/cart/${id}`,
    options: {
      method: "DELETE"
    },
    transform: (responseBody) => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { cartDetails: items };
    },
    update: {
      cartDetails: (prevValue, newValue) => {
        customToast({ title: "Success", description: " Domain successfully deleted from cart" })
        return newValue
      }
    }
  }
}

export const clearCartMutation = (data) => {
  return {
    url: `/api/login`,
    body: data,
    options: {
      method: "DELETE"
    },
    transform: (responseBody) => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { cartDetails: items };
    },
    update: {
      cartDetails: (prevValue, newValue) => newValue
    }
  }
}

export const updateCartMutation = (data) => {
  return {
    url: `/api/cart`,
    body: data,
    options: {
      method: "PUT"
    },

    transform: (responseBody) => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { cartDetails: items };
    },
    update: {
      cartDetails: (prevValue, newValue) => newValue
    }
  }
}

export const getCartRequest = {
  url: `/api/cart`,
  transform: (responseBody) => {
    const { result } = responseBody || {};
    const { items = [] } = result || {};
    return { cartDetails: items };
  },
  update: {
    cartDetails: (prevValue, newValue) => newValue
  }
}
export const getCartMutation = () => {
  return {
    url: `/api/cart`,
    options: {
      method: "GET"
    },
    transform: (responseBody) => {
      const { result } = responseBody || {};
      const { items = [] } = result || {};
      return { cartDetails: items };
    },
    update: {
      cartDetails: (prevValue, newValue) => newValue
    }
  }
}

export const moveToWishlistMutation = ({ id }) => {
  return {
    url: `/api/cart/${id}/move-to-wishlist`,
    options: {
      method: "PUT"
    },
    transform: (responseBody) => {
      const { result } = responseBody || {};
      const { items = [], wishlistItems = [] } = result || {};
      return { cartDetails: items, wishlist: wishlistItems };
    },
    update: {
      wishlist: (prevValue, newValue) => newValue,
      cartDetails: (prevValue, newValue) => newValue
    }
  }
}

export const getCartDetails = state => state.entities.cartDetails
