import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn, logout } from 'utils/Auth';
import { useToast } from '@chakra-ui/toast';
const PrivateRoute = ({ path, exact, component: Component, ...rest }) => {
  const toast = useToast();

  if (!isLoggedIn()) {
    logout();
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={props => {
        return isLoggedIn() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PublicRoute = ({ path, exact, component: Component, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    render={props => {
      return !localStorage.getItem('token') ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default function createRoutes(props) {
  const Login = React.lazy(() => import('pages/auth/Login'));
  const ForgotPassword = React.lazy(() => import('pages/auth/ForgotPassword'));
  const ResetPassword = React.lazy(() => import('pages/auth/ResetPassword'));
  const ChangePassword = React.lazy(() => import('pages/auth/ChangePassword'));
  const Signup = React.lazy(() => import('pages/auth/Signup'));
  const Dashboard = React.lazy(() => import('pages/Dashboard'));
  const Hero = React.lazy(() => import('pages/Hero'));
  const Cart = React.lazy(() => import('pages/Cart'));
  const Wishlist = React.lazy(() => import('pages/Wishlist'));
  const Support = React.lazy(() => import('pages/support'));
  const Order = React.lazy(() => import('pages/Cart/OrderFinal'));
  // const TokenOrder = React.lazy(() => import('pages/TokenCart/OrderFinal'));
  const Redirect = React.lazy(() => import('components/common/Redirect'));
  const CompleteDomainRegistration = React.lazy(() =>
    import('pages/Cart/CompleteOrder'),
  );
  const Transactions = React.lazy(() => import('pages/Cart/MyOrders'));
  const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
  const PremiumDomains = React.lazy(() => import('pages/PremiumDomains'));

  const MyDomains = React.lazy(() => import('pages/MyDomains'));
  const Profile = React.lazy(() => import('pages/User/Profile'));
  // const UserGuide = React.lazy(() => import('pages/UserGuide/UserGuide'));
  const VerifyAccount = React.lazy(() => import('pages/auth/VerifyAccount'));
  const ManageMyDomains = React.lazy(() => import('pages/ManageDomains'));

  /** Trademark Pages */
  const TrademarkRequests = React.lazy(() =>
    import('pages/Trademark/RequestList'),
  );

  const RequestTrademark = React.lazy(() => import('pages/Trademark/index'));

  const DomainTransferRequestsPage = React.lazy(() =>
    import('pages/DomainTransferRequests'),
  );
  const CryptoTransfer = React.lazy(() => import('pages/CryptoTransfer'));
  const TransferHistory = React.lazy(() =>
    import('pages/CryptoTransfer/history'),
  );

  return (
    <>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
      <PrivateRoute exact path="/change-password" component={ChangePassword} />
      <PublicRoute
        exact
        path="/reset-password/:userId/:token"
        component={ResetPassword}
      />
      <PublicRoute exact path="/signup" component={Signup} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/wishlist" component={Wishlist} />
      <Route exact path="/premium-domains" component={PremiumDomains} />
      <PrivateRoute
        exact
        path="/request-trademark/:domainName"
        component={RequestTrademark}
      />
      <PrivateRoute
        exact
        path="/trademark-requests"
        component={TrademarkRequests}
      />

      <PrivateRoute exact path="/order/:orderId" component={Order} />
      <PrivateRoute
        exact
        path="/domains/:domainId"
        component={CompleteDomainRegistration}
      />
      <PrivateRoute
        exact
        path="/manage-domains/:orderId"
        component={ManageMyDomains}
      />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute exact path="/my-domains" component={MyDomains} />
      <PrivateRoute
        exact
        path="/domain-transfer-requests"
        component={DomainTransferRequestsPage}
      />
      <PrivateRoute exact path="/transactions" component={Transactions} />
      <PrivateRoute exact path="/transfer" component={CryptoTransfer} />
      <PrivateRoute
        exact
        path="/transfer/history"
        component={TransferHistory}
      />
      <Route exact path="/app/my-domains" component={MyDomains} />
      {/*<Route exact path="/premium-domains" component={PremiumDomains} />*/}
      <Route
        exact
        path="/docs"
        render={props => (
          <Redirect href="https://whitepaper.nexbloc.com/docs/" {...props} />
        )}
      />
      <Route exact path="/support" component={Support} />
      <Route exact path="/" component={Hero} />
      <Route
        exact
        path="/verify-account/:userId/:token"
        component={VerifyAccount}
      />
    </>
  );
}
