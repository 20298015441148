import { updateCartEntity } from "entities/cartEntity";
import { addToWishlistEntity } from "pages/Wishlist/entities";
import { isLoggedIn } from "utils/Auth";

export const getCartItems = () => {
  const cart = localStorage.getItem('cart');
  try{
    return JSON.parse(cart) || [];
  }
  catch(err){
    return [];
  }
  
};

export const setCartItems = data => {
  localStorage.setItem('cart', JSON.stringify(data));
};

export const addCartItemLocalStorage = newItem => {
  const cartItems = getCartItems();
  const updateCartItem = [...cartItems, {...newItem,_id:cartItems.length+1}];
  localStorage.setItem('cart', JSON.stringify(updateCartItem));
};

export const moveToWishlistEntity = (id,cart,moveToWishlistApi)=>{
  if(isLoggedIn()){
    moveToWishlistApi({id})
  }
  else{
    const newWishlistItemIndex = cart.findIndex(item => item._id === id);
    const newWishlistItem = cart.splice(newWishlistItemIndex,1);
    if(newWishlistItem.length>0){
      addToWishlistEntity(newWishlistItem[0]);
    }
    updateCartEntity([...cart]);
  }
}