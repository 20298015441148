export const qie = {
  id: 5656,
  name: 'QIE',
  network: 'qie',
  nativeCurrency: {
    decimals: 18,
    name: 'QIE',
    symbol: 'QIE',
  },
  rpcUrls: {
    public: { http: ['https://rpc-main3.qiblockchain.online'] },
    default: { http: ['https://rpc-main3.qiblockchain.online'] },
  },
  blockExplorers: {
    default: { name: 'Mainnet', url: 'https://mainnet.qiblockchain.online' },
  }
};