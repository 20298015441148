import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

export default function FullPageLoader() {
  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="4px"
        size="xl"
      />
    </Flex>
  );
}
