import { Box, Stack, Flex, StackDivider } from '@chakra-ui/react';
import * as React from 'react';
import Copyright from './Copyright';
import LinkGrid from './LinkGrid';
import SocialMediaLinks from './SocialMediaLinks';
import SubscribeForm from './SubscribeForm';

const Footer = () => (
  <Box position={'relative'}>
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      bgImage="url('/images/Grains-min.jpg')" 
      bgPosition="center"
      bgRepeat="repeat"
      bgSize="cover"
      opacity={.9}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgColor: "#121422",
        opacity: 0.9,  
      }}
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgColor: "#383638",
        opacity: .1,  
        zIndex: -1,  
      }}
    >
    </Box>
    <Box
      as="footer"
      role="contentinfo"
      position={'relative'}
      bg={"rgba(210, 200, 200, 0.049)"}
    >
      <Stack spacing="10" divider={<StackDivider />} mx="auto" maxW="7xl" py="8" px={{ base: '4', md: '8', }}>
        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          spacing={{
            base: '10',
            lg: '28',
          }}
        >
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            spacing={{
              base: '10',
              md: '20',
            }}
          >
            <LinkGrid
              spacing={{
                base: '10',
                md: '20',
                lg: '28',
              }}
              flex="1"
            />
            <SubscribeForm
              width={{
                base: 'full',
                md: 'sm',
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction={{
            base: 'column-reverse',
            md: 'row',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Copyright />
          <Flex
            alignItems={{ base: 'center', md: 'flex-end' }}
            flexDirection="column"
          >
            <SocialMediaLinks />
          </Flex>
        </Stack>
      </Stack>
    </Box>
  </Box>
);

export default Footer;
