export const qieDollarValueRequest = () => {
  return {
    url: `https://st-admin.hovr.site/api/swap/getusdprice/qie`,
    transform: body => ({
      qieDollarValue: body.rate,
    }),
    update: {
      qieDollarValue: (oldValue, newValue) => newValue,
    },
  };
};

export const getQieDollarValue = state => state.entities.qieDollarValue;
