import { Text } from '@chakra-ui/react';
import * as React from 'react';

const Copyright = (props) => (
  <Text textAlign='left'  fontSize="sm" {...props}>
    Copyright
    &copy;
    {' '}
    {new Date().getFullYear()}
    {' '}
    Hovr Foundation Inc. <br/> Powered by NexBloc All rights reserved.
  </Text>
);

export default Copyright;
