import { ButtonGroup, IconButton } from '@chakra-ui/react';
import * as React from 'react';
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { BsDiscord } from 'react-icons/bs';
import { FaTelegram } from 'react-icons/fa';
import { FaWeibo } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaRedditAlien } from 'react-icons/fa';
const SocialMediaLinks = props => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      target="_blank"
      href="https://twitter.com/HovrSite"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.facebook.com/hovr.site/"
      aria-label="Facebbok"
      icon={<AiOutlineFacebook fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.youtube.com/watch?v=CMqRqd97rQg"
      aria-label="Youtube"
      icon={<AiOutlineYoutube fontSize="20px" />}
    />
    <IconButton
      as="a"
      target="_blank"
      href="https://www.instagram.com/hovr.site/"
      aria-label="Instagarm"
      icon={<AiOutlineInstagram fontSize="20px" />}
    />
    <IconButton
      as="a"
      href="https://www.reddit.com/r/qiblockchain/?rdt=58368"
      target="_blank"
      aria-label="Join Subreddit"
    >
      <FaRedditAlien />
    </IconButton>
    <IconButton
      as="a"
      href="https://discord.com/invite/9HCNTyqkwa"
      target="_blank"
      aria-label="Join Discord"
    >
      <BsDiscord />
    </IconButton>
    <IconButton
      as="a"
      href="https://weibo.com/pawsome"
      target="_blank"
      aria-label="Join Weibo"
    >
      <FaWeibo />
    </IconButton>
    <IconButton
      as="a"
      href="https://github.com/Qi-Blockchain"
      target="_blank"
      aria-label="Look Github Repo"
    >
      <FaGithub />
    </IconButton>
    <IconButton
      as="a"
      href="https://t.me/+0nWibUYo2800MmZl"
      target="_blank"
      aria-label="Join Telegram"
    >
      <FaTelegram />
    </IconButton>
  </ButtonGroup>
);

export default SocialMediaLinks;
