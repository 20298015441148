import { actionTypes } from "redux-query";
import history from "./history";
import { customToast } from "components/common/Toast";
import { logout } from "utils/Auth";

const ignore401MessageRoutes = [
  '/api/cart',
  '/api/wishlist',
  '/api/user'
]

const {
  MUTATE_ASYNC,
  REQUEST_ASYNC,
  MUTATE_FAILURE,
  REQUEST_FAILURE
} = actionTypes;

const showError = ({status,url,responseBody})=>{
  const errorMessage = responseBody && (responseBody.error || responseBody.message || responseBody.result) || 'Something went wrong';
    if(status===401){
      if(!ignore401MessageRoutes.includes(url)){
        // customToast({status:"error",title:"Unauthorized",description:errorMessage || "Session expired. Please login again",placement:"top-right"});
        localStorage.clear()
        history.push("/login")
      }
    }
    else if(errorMessage && typeof(errorMessage) === 'string'){
      customToast({status:"error",title:"Error",description:errorMessage,placement:"top-right"});
    }else{
      customToast({status:"error",title:"Error",description: errorMessage,placement:"top-right"});
    }
}


export const jwtMiddleware = (store) => (next) => (action) => {
  const jwtToken = localStorage.getItem("token");
  if (
    action &&
    (action.type === MUTATE_ASYNC || action.type === REQUEST_ASYNC)
  ) {
    // This is a redux-query action so add the JWT header
    const options = action.options || {};
    const headers = options.headers || {};
    const updatedAction = {
      ...action,
      options: {
        ...options,
        headers: {
          ...headers,
          Authorization: `Bearer ${jwtToken}`,
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
    next(updatedAction);
  } else if (action?.type === MUTATE_FAILURE || action?.type === REQUEST_FAILURE ) {
    let {status,url,responseBody} = action
    showError({status,url,responseBody})
    next(action);
  } else {
    // This isn't a redux-query action so just let it pass through
    next(action);
  }
};

