import { ethers } from 'ethers';
import { checkChainId, getSignature, getWeb3Provider, getTokenId } from './web3';
import zoneContract from './build/contracts/Zone.json';
import registrarContract from './build/contracts/RootRegistry.json';
import domainMetadataContract from './build/contracts/DomainMetadata.json';
import domainMetadataContractv2 from './build/contracts/Metadata.json';

import PaymentGatewayContract from './build/contracts/PaymentGateway.json';

export const getQiNetwork = () => {
  return process.env.REACT_APP_QI_NETWORK;
};

export const getQiNetworkDetails = () => {
  const qiNetwork = getQiNetwork();

  return {
    chainId: 0x1618,
    chainName: 'QI Blockchain - Mainnet',
    nativeCurrency: { name: 'QIE', symbol: 'QIE', decimals: 18 },
    rpcUrls: ['https://rpc-main1.qiblockchain.online'],
    blockExplorerUrls: ['https://mainnet.qiblockchain.online'],
  }

  if (qiNetwork === 'mainnet') {
    return {
      chainId: '0x1618',
      chainName: 'QI Blockchain - Mainnet',
      nativeCurrency: { name: 'QIE', symbol: 'QIE', decimals: 18 },
      rpcUrls: ['https://rpc-main3.qiblockchain.online'],
      blockExplorerUrls: ['https://mainnet.qiblockchain.online'],
    };
  } else {
    return {
      chainId: '0x1618',
      chainName: 'QI Blockchain - Testnet',
      nativeCurrency: { name: 'QIE', symbol: 'QIE', decimals: 18 },
      rpcUrls: ['https://testnetrpc.qiblockchain.online'],
      blockExplorerUrls: ['https://testnet.qiblockchain.online'],
    };
  }
};

export const PAYMENT_GATEWAY_CONTRACT_ADDRESS =
  process.env.REACT_APP_PAYMENT_GATEWAY_CONTRACT_ADDRESS;

export const REGISTRAR_CONTRACT_ADDRESS =
  process.env.REACT_APP_QI_REGISTRAR_CONTRACT_ADDRESS;

export const DOMAIN_METADATA_CONTRACT_ADDRESS =
  process.env.REACT_APP_DOMAIN_METADATA_CONTRACT_ADDRESS;

export const QIE_ZONE_CONTRACT_ADDRESS =
  process.env.REACT_APP_QIE_ZONE_CONTRACT_ADDRESS;

export const PAYMENT_CONTRACT_ADDRESS = process.env.REACT_APP_QIE_PAYMENT_CONTRACT_ADDRESS;//"0x1887c8aA622a3823F0ce25eBa49E079493239289"

export const reserveDomain = async ({ finalAmount, cart }) => {
  const provider = await getWeb3Provider();
  const signer = provider.getSigner();
  const account = await signer.getAddress();

  const networkDetails = getQiNetworkDetails();

  await checkChainId(networkDetails.chainId);
  // const tx = await signer.sendTransaction({
  //   from: account,
  //   to: PAYMENT_GATEWAY_CONTRACT_ADDRESS,
  //   value: ethers.utils.parseUnits(`${amount}`, 'ether'),
  //   gasLimit: 3200000,
  // });

  const paymentContractInstance = new ethers.Contract(
    PAYMENT_CONTRACT_ADDRESS,
    PaymentGatewayContract.abi,
    signer,
  );

  if (!paymentContractInstance) {
    throw Error('paymentContractInstance is undefined');
  }

  const domains = cart.items.map(item => item);

  let tx;
  for (let i = 0; i < domains.length; i++) {
    const domain = domains[i];
    const domainValues = domain?.domainName?.split('.');

    const salt = Math.round(Math.random() * 1000000);

    const message = 'Approve QIE to mint domain to your wallet';


    const order = [
      account,
      domainValues[1],
      domainValues[0],
      domainValues[1],
    ];

    console.log("order:", order);
    try {
      openWallet();
      tx = await paymentContractInstance.mint(order, { value: ethers.utils.parseUnits(`${domain.price}`, 'ether'), gasLimit: 5200000 });
    } catch (e) {
      console.log(e);
    }
    console.log("------------------")
  }

  console.log("tx--", tx);

  return tx;
};

export const mintDomain = async ({ domain }) => {
  try {
    const domainNetwork = domain?.tld?.network;

    if (!domainNetwork) {
      throw Error("Can't find domainNetwork");
    }

    const domainValues = domain?.domainName?.split('.');

    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const account = await signer.getAddress();

    const salt = Math.round(Math.random() * 1000000);

    const message = 'Approve QIE to mint domain to your wallet';

    if (domainNetwork === 'qie') {
      const networkDetails = getQiNetworkDetails();

      const chainId = networkDetails?.chainId;

      await checkChainId(chainId);

      console.log("chain id");

      const order = [
        account,
        domainValues[1],
        domainValues[0],
        domainValues[1],
        salt,
        message,
      ];

      // console.log({ order });

      const sig = await getSignature(
        signer,
        ...order,
        5656,
        REGISTRAR_CONTRACT_ADDRESS,
      );

      console.log("sig");
      // console.log({ sig });

      const registrarInstance = new ethers.Contract(
        REGISTRAR_CONTRACT_ADDRESS,
        registrarContract.abi,
        signer,
      );

      openWallet();
      const tx = await registrarInstance.mintDomain(order, sig, true, {
        gasLimit: 5200000,
      });

      const txResponse = await tx.wait();

      // console.log({ txResponse });

      return txResponse.transactionHash || tx.hash;
    }
  } catch (e) {
    console.log(e);
  }
};

export const saveDomainMetadata = async (
  domainName,
  metadataFields,
  metadataValue,
) => {
  try {
    // console.log({ domainName, metadataFields, metadataValue });
    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const networkDetails = getQiNetworkDetails();

    const chainId = networkDetails?.chainId;

    await checkChainId(chainId);

    const metadataContractInstance = new ethers.Contract(
      DOMAIN_METADATA_CONTRACT_ADDRESS,
      domainMetadataContractv2.abi,
      signer,
    );

    if (!metadataContractInstance) {
      throw Error('metadataContractInstance is undefined');
    }

    openWallet();
    const tx = await metadataContractInstance.addMetadata(
      domainName,
      metadataFields,
      metadataValue,
      {
        gasLimit: 400000,
      },
    );

    const txResponse = await tx.wait();

    // console.log({ txResponse });

    return txResponse;
  } catch (e) {
    console.log(e);
  }
};

export const transferDomain = async (
  domainName,
  domainOwner,
  receiverWalletAddress,
) => {
  const provider = await getWeb3Provider();
  const signer = provider.getSigner();

  const networkDetails = getQiNetworkDetails();

  const chainId = networkDetails?.chainId;

  await checkChainId(chainId);

  const transferContractInstance = new ethers.Contract(
    QIE_ZONE_CONTRACT_ADDRESS,
    zoneContract.abi,
    signer,
  );

  if (!transferContractInstance) {
    throw Error('transferContractInstance is undefined');
  }

  const tokenId = await getTokenId(domainName); //await getDomainTokenId(domainName);

  openWallet();
  const tx = await transferContractInstance.transferFrom(
    domainOwner,
    receiverWalletAddress,
    tokenId,
    {
      gasLimit: 5200000,
    },
  );

  // console.log(tx);

  return tx.hash;
};

export const getDomainTokenId = async domainName => {
  const provider = await getWeb3Provider();

  const registrarInstance = new ethers.Contract(
    REGISTRAR_CONTRACT_ADDRESS,
    registrarContract.abi,
    provider,
  );

  const tx = await registrarInstance.domainInfo(domainName);

  // console.log({ tx });

  return tx[1];
};

export const getDomainTxHash = async (domainName, cb) => {
  const provider = await getWeb3Provider();

  const registrarInstance = new ethers.Contract(
    REGISTRAR_CONTRACT_ADDRESS,
    registrarContract.abi,
    provider,
  );

  const tx = await registrarInstance.domainInfo(domainName);

  cb(tx);
  return;
};


export const openWallet = () => {
  if (window.selectedWallet === "qiemobile" && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    window.open('qiemobilewalletconnect://', '_blank')
  }
}