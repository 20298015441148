import jwt_decode from "jwt-decode";
export const isLoggedIn = ()=>{
    const token = localStorage.getItem('token')
    if(!token) return false;
    try {
        const tokenExpiry = jwt_decode(token)?.exp;
        if(!tokenExpiry){
            return false;
        }
        if (Date.now() >= tokenExpiry * 1000) {
            return false;
          }
        return true;
    } catch (error) {
        return false;   
    }
}

export const logout = () => {
    localStorage.clear('token');
    // window.location.reload();
}