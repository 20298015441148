import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      a: {
        color: "brand.600",
        textDecoration: "none",
        _hover: {
          textDecoration: "underline",
        }
      },
      button: {
        _hover: {
          transitionDuration: '0s !important'
        }
      }
    }
  },
  fonts: {
    heading: `'Porter Sans', sans-serif`,
    body: `'Porter Sans', sans-serif`,
  },
  colors: {
    brand: {
      100: '#9d86fb',
      200: '#000',
      300: '#000',
      400: '#fff',
      500: '#ff136d',
      600: '#FF136D',
      700: '#616161',
      800: '#35A5F5',
      900: '#35A5F5',
    },
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Raleway', sans-serif",
  },
},
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Button'],
  }));

export default theme;
