import React from 'react';
import { Text, Button, HStack,Stack, Box, IconButton } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { useMutation } from 'redux-query-react';
import {resendVerificationEmailMutation} from 'pages/User/userEntity'

export default function VerifyEmailBanner({ closeBanner }) {
  const [{isPending},resendVerificationEmail] = useMutation(resendVerificationEmailMutation);
  return (
    <HStack
      status="error"
      align={{base:"flex-start",md:"center"}}
      justify={{base:"center",md:"space-between"}}
      spacing="4"
      // bg="red.100"
      padding="2"
    >
      <Stack direction={{base:"column",md:"row"}} justify="center" width="full" spacing="8">
        <HStack>
          <Box color="red" fontSize="xl">
            <FiAlertCircle 
              fontSize="2xl"
              fontWeight="bold"
            />
          </Box>
          <Text fontSize="sm">
            Please verify your email to enjoy all features
          </Text>
        </HStack>
        
        <Button colorScheme="red" size="sm" onClick={resendVerificationEmail} isLoading={isPending} loadingText="Sending email...">Resend verification email</Button>
      </Stack>
      <Box>
        <IconButton
          onClick={closeBanner}
          icon={<IoMdClose />}
          variant="ghost"
          size="sm"
          fontSize="2xl"
          fontWeight="bold"
        />
      </Box>
    </HStack>
  );
}
