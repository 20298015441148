import React, { useEffect, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import {
  ButtonGroup,
  IconButton,
  Button,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
  Box,
  Spinner,
  ModalCloseButton,
  ModalOverlay
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { customToast } from '../common/Toast';
import {
  getWeb3Account,
  getWeb3Provider,
  getWeb3Providera,
  getWeb3Providerb,
  shortenAddress,
} from 'utils/BlockChain/web3';

import qi from './images/qi.png';
import metamask from './images/metamask.png';
import walletconnect from './images/walletconnect.png';

export const getIsWalletConnected = state => state.wallet.isWalletConnected;
export const getWalletAccount = state => state.wallet.account;

function ConnectWallet({ width = '' }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const isWalletConnected = useSelector(getIsWalletConnected);
  const account = useSelector(getWalletAccount);

  const [loadingWallet, setLoadingWallet] = useState(false);

  useEffect(() => {
    const handleAccountsChanged = () => {
      connectWallet();
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener(
          'accountsChanged',
          handleAccountsChanged,
        );
      }
    };
  }, [window.ethereum]);

  const connectWallet = async () => {
    try {
      const toastId = 'wallet-success';
      const provider = await getWeb3Provider();
      console.log("-------------------")
      console.log("provider", provider)


      console.log("signer", provider.getSigner());

      if (!provider) {
        throw new Error('missing provider');
      }

      const account = await getWeb3Account(provider);
      console.log("account", account)

      if (account) {
        dispatch({
          type: 'CONNECT_WALLET',
          data: { account },
        });

        if (!toast.isActive(toastId))
          customToast({
            id: toastId,
            description: 'Wallet connected Successfully!',
            status: 'success',
          });
      } else {
        throw Error();
      }
    } catch (error) {
      console.error(error);
      console.error('Error connecting wallet =>', error.message);

      if (error?.message?.includes('missing provider')) {
        customToast({
          title: 'Error',
          description: 'Wallet not found! Please install Metamask.',
          status: 'error',
        });
      } else if (error?.message?.includes('User rejected the request')) {
        customToast({
          title: 'Error',
          description: 'User rejected the connection request!',
          status: 'error',
        });
      } else {
        customToast({
          title: 'Error',
          description: 'Error in connecting to wallet!',
          status: 'error',
        });
      }
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onWalletSelect = async (selectedWallet) => {
    setLoadingWallet(true);
    window.selectedWallet = selectedWallet;
    dispatch({
      type: 'SELECT_WALLET',
      data: { selectedWallet },
    });
    await connectWallet();
    setLoadingWallet(false);
    onClose();
  }

  const renderWalletButton = () => {
    if (!isWalletConnected) {
      return (
        <>
          <Modal onClose={() => { onClose(); setLoadingWallet(false); }} size='xs' isOpen={isOpen} isCentered
            motionPreset='slideInBottom' closeOnOverlayClick={false} >
            <ModalContent pb={7} containerProps={{
              zIndex: '50',
            }} >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Select Wallet</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {loadingWallet && (<Box backdropFilter='auto' backdropBlur='2px' style={{
                    position: 'absolute',
                    width: '84%',
                    height: '70%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingBottom: '25px'
                  }}>
                    <Spinner
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
                    />
                  </Box>)}
                  <Box cursor={loadingWallet ? null : "pointer"} onClick={() => onWalletSelect("qie")} display={'flex'} gap={2} maxW='sm' boxShadow='sm' _hover={{ background: '#f2f2f0' }} m={2} p={3} borderWidth='1px' borderRadius='lg'>
                    <img src={qi} width={24} /> QIE Wallet
                  </Box>
                  <Box cursor={loadingWallet ? null : "pointer"} onClick={() => onWalletSelect("qiemobile")} display={'flex'} gap={2} maxW='sm' boxShadow='sm' _hover={{ background: '#f2f2f0' }} m={2} p={3} borderWidth='1px' borderRadius='lg'>
                    <img src={qi} width={24} /> QIE Mobile Wallet
                  </Box>
                  <Box cursor={loadingWallet ? null : "pointer"} onClick={() => onWalletSelect("metamask")} display={'flex'} gap={2} maxW='sm' boxShadow='sm' _hover={{ background: '#f2f2f0' }} m={2} p={3} borderWidth='1px' borderRadius='lg'>
                    <img src={metamask} width={24} />MetaMask
                  </Box>
                  <Box cursor={loadingWallet ? null : "pointer"} onClick={() => onWalletSelect("walletconnect")} display={'flex'} gap={2} maxW='sm' boxShadow='sm' _hover={{ background: '#f2f2f0' }} m={2} p={3} borderWidth='1px' borderRadius='lg'>
                    <img src={walletconnect} width={24} />WalletConnect
                  </Box>
                </ModalBody>
              </ModalContent>
            </ModalContent>
          </Modal>
          <Button onClick={onOpen} id="connect-button" w={width}
           color="#ff136d"
           fontWeight="normal"
           borderRadius="full"
           zIndex="1"
           padding="1.5rem"
           bg="none"
           _hover={{ bg: 'none' }}
           _after={{
             content: '""',
             position: 'absolute',
             top: '0',
             left: '0',
             right: '0',
             bottom: '0',
             zIndex: '-1',
             borderRadius: 'full',
             padding: '1px', // Adjust border thickness
             background: 'linear-gradient(90deg, #453FD6 0%, #FF136D 100%)',
             WebkitMask:
               'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
             mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
             WebkitMaskComposite: 'destination-out',
             maskComposite: 'subtract',
           }}>
            Connect Wallet
          </Button>
        </>
      );
    }

    return (
      <ButtonGroup isAttached variant="outline">
        <Button>{shortenAddress(account)}</Button>
        <Tooltip label="Copy Address">
          <IconButton
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(account);
                customToast({
                  description: 'Address copied to clipboard!',
                  status: 'success',
                });
              } catch (error) {
                console.error('Error copying address =>', error);
              }
            }}
            aria-label="Copy wallet address to clipboard"
            icon={<BiCopy />}
          />
        </Tooltip>
      </ButtonGroup>
    );
  };

  return renderWalletButton();
}

export default ConnectWallet;
