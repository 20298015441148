import { Image } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './res/qielogo.png';
import mobileLogo from './res/fav.ico'

export default function Logo() {
  return (
    <Link to="/">
      <Image width='130px' padding={2} mt={2} className={'logo-img'} src={logo} />
    </Link>
  );
}

export function MobileLogo() {
  return (
    <Link to="/">
      <Image src={mobileLogo} width="36px" height="36px" />
    </Link>
  );
}
